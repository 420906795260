/* eslint-disable no-unused-vars */
import Cookie from 'js-cookie';
import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import ReactDOM from 'react-dom';
import Popup from 'reactjs-popup';
import { ethers } from 'ethers';
import { AppContext } from '../../context/AppContext';
import ApiHelper from '../../data/ApiHelper';
import Cart from '../../data/Cart';
import './PayPalButton.css';

// eslint-disable-next-line no-undef
const ReactPayPalButton = paypal.Buttons.driver('react', { React, ReactDOM });

export default function PayPalButton(props) {
    const { state, dispatch } = useContext(AppContext);
    const { total, setIsBusy } = props;
    const [senderAddress, setSenderAddress] = useState(null);
    const [error, setError] = useState(null);
    const [txs, setTxs] = useState(null);
    const { cart, user } = state;
    const cartId = get(cart, 'id', '');
    const coupon = get(cart, 'coupon', '');
    const ethValue = total / 4549.2;
    const finalPrice = `${ethValue} ETH`;

    const amountToPay = cart.totalPriceAfterCoupon
        ? cart.totalPriceAfterCoupon
        : cart.totalCartPrice;

    const createOrder = (data, actions) =>
        actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: amountToPay,
                    },
                },
            ],
        });

    const onCheckout = (paymentType) => {
        setIsBusy(true);
        const token = Cookie.get('token');
        const reports = '';
        const accountId = localStorage.getItem('userGameDbId');

        const data = {
            AccountId: accountId,
            CharacterName: '',
            OrderDetails: cart,
            Coupon: coupon,
            user_id: user.id,
            PaymentType: paymentType,
        };
        const onSuccessOrder = (responseOrder) => {
            let totalOrderedCoin = 0;

            cart.packages.forEach((item) => {
                totalOrderedCoin += item.coin * item.totalProductQuantity;
            });

            const totalUpdatedCoin = totalOrderedCoin + user.coin * 1;

            const updateData = {
                coin: totalUpdatedCoin,
            };

            const onSuccessUserUpdate = (response) => {
                const updatedUser = get(response, 'data', null);
                dispatch({ type: 'UPDATE_USER', user: updatedUser });
                const updatedCart = new Cart(cartId);

                const onSuccessCartUpdate = (responseCart) => {
                    const cartData = get(response, 'data', {});
                    const cartDetails = get(cartData, 'cartDetails', []);
                    dispatch({ type: 'UPDATE_CART', updatedCart });
                };

                const onErrorCartUpdate = () => {};

                ApiHelper.carts
                    .updateCart(cartId, { cartDetails: JSON.stringify(updatedCart) }, token)
                    .then(onSuccessCartUpdate)
                    .catch(onErrorCartUpdate);
            };

            const onErrorUserUpdate = () => {};

            ApiHelper.user
                .update(updateData, user.id, token)
                .then(onSuccessUserUpdate)
                .catch(onErrorUserUpdate);
        };

        const onErrorOrder = () => {};

        const onFinish = () => {
            setIsBusy(false);
        };

        ApiHelper.orders
            .createOrder(data, token)
            .then(onSuccessOrder)
            .catch(onErrorOrder)
            .finally(onFinish);
    };

    const onApprove = async (data, actions) => {
        await actions.order.capture();
        onCheckout('PayPal');
    };
    const startPayment = async ({ ether, addr }) => {
        try {
            if (!window.ethereum) {
                throw new Error('No crypto wallet found. Please install it.');
            }

            await window.ethereum.send('eth_requestAccounts');

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();

            ethers.utils.getAddress(addr);

            const tx = await signer.sendTransaction({
                to: addr,
                value: ethers.utils.parseEther(ether),
            });

            setTxs([tx]);

            if (tx) {
                onCheckout('MetaMask');
            }
        } catch (err) {
            setError(err);
            console.log(err, 'err');
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const ethPriceResponse = await fetch(
            'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR'
        );

        const { USD: ehtInUsd } = await ethPriceResponse.json();
        const price = total / ehtInUsd;

        setError();

        await startPayment({
            ether: price.toString(),
            addr: process.env.REACT_APP_META_MASK_RECIEVER,
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <button type="submit" id="wallet_btn" className="crypto_btn">
                    MetaMask
                </button>
            </form>
            <ReactPayPalButton
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
            />
        </>
    );
}
