import React from 'react';
import './TopGuild.css';

export default function TopGuild(props) {
    const { name, guild, number, total } = props;
    return (
        <li className="top-list">
            <span className="top-number">{number}</span>
            <span className="top-name">
                <a href="/" title="nickname">
                    {name}
                </a>
            </span>
            <span className="top-lvl">{guild}</span>
            <span className="top-Res">{total}</span>
        </li>
    );
}
