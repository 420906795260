import Cookie from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Popup from 'reactjs-popup';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation } from 'swiper';
import leftArrow from '../../assets/images/arrow-left-1.jpg';
import rightArrow from '../../assets/images/arrow-right-1.jpg';
import BackgroundCharacter1 from '../../assets/images/bg-character-img-1.png';
import BackgroundCharacter2 from '../../assets/images/bg-character-img-2.png';
import BackgroundCharacter3 from '../../assets/images/bg-character-img-3.png';
import BackgroundCharacter4 from '../../assets/images/bg-character-img-4.png';
import { AppContext } from '../../context/AppContext';
import UsernameWallet from '../common/UsernameWallet';
import './AccountPanel.css';
import ApiHelper from '../../data/ApiHelper';

SwiperCore.use([Navigation]);

export default function AccountPanel(props) {
    const { state, dispatch } = useContext(AppContext);
    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const { user } = state;
    const { match } = props;
    const { path } = match;
    const [orders, setOrders] = useState();
    const [userCharacters, setUserCharacters] = useState([]);

    const onLogout = () => {
        Cookie.remove('token');
        dispatch({ type: 'LOGOUT' });
        window.localStorage.setItem('logout', Date.now());
    };
    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const token = Cookie.get('token');
        const params = {
            user_id: user.id,
            _sort: 'published_at:DESC',
            _limit: 5,
        };

        const onSuccess = (response) => {
            const data = get(response, 'data', []);
            setOrders(data);
        };

        const onError = () => {};

        ApiHelper.orders.getOrder(params, token).then(onSuccess).catch(onError);
    }, [user.id]);
    useEffect(() => {
        const token = Cookie.get('token');
        const userParams = {
            AccountId: +localStorage.getItem('userGameDbId'),
        };
        const onSuccess = (response) => {
            const data = get(response, 'data.0', []);
            const tempData = [];
            for (let i = 0; i < data?.length; i += 1) {
                switch (data?.[i]?.Class) {
                    case 1:
                        tempData.push({ ...data?.[i], characterImage: BackgroundCharacter1 });
                        break;
                    case 2:
                        tempData.push({ ...data?.[i], characterImage: BackgroundCharacter3 });
                        break;
                    case 3:
                        tempData.push({ ...data?.[i], characterImage: BackgroundCharacter2 });
                        break;
                    case 4:
                        tempData.push({ ...data?.[i], characterImage: BackgroundCharacter4 });
                        break;
                    default:
                        tempData.push({ ...data?.[i], characterImage: BackgroundCharacter1 });
                        break;
                }
            }
            setUserCharacters(tempData);
        };

        const onError = () => {};

        ApiHelper.games.getUserCharacters(userParams, token).then(onSuccess).catch(onError);
    }, []);

    return (
        <div className="page-content">
            <div className="page-top">ACCOUNT PANEL</div>
            <div className="text-text">
                <div className="cart-user flex-s-c">
                    <UsernameWallet username={user.username} coin={user.coin} />
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <span className="cart-user-exit a" onClick={onLogout} />
                </div>
                <div className="menu-news-n">
                    <div className="menu-news flex-s-c">
                        <li className={path === '/myaccount' ? 'active-news' : ''}>
                            <Link to="/myaccount" className="a">
                                Dashboard
                            </Link>
                        </li>
                        <li className={path === '/myaccount/orders' ? 'active-news' : ''}>
                            <Link to="/myaccount/orders" className="a">
                                My Orders
                            </Link>
                        </li>
                        <li className={path === '/myaccount/edit' ? 'active-news' : ''}>
                            <Link to="/myaccount/edit" className="a">
                                Edit Account
                            </Link>
                        </li>
                        <li className={path === '/myaccount/send-coins' ? 'active-news' : ''}>
                            <Link to="/myaccount/send-coins" className="a">
                                Send Coins
                            </Link>
                        </li>
                    </div>
                </div>
                <div className="block-character flex-s-c">
                    <div
                        style={{
                            paddingRight: '10px',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            position: 'relative',
                            zIndex: '10000',
                        }}
                    >
                        <Swiper
                            slidesPerView={2}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                        >
                            {userCharacters.map((character) => (
                                <SwiperSlide>
                                    <Popup
                                        position="center center"
                                        modal
                                        contentStyle={{
                                            width: '400px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        trigger={
                                            <div className="bg-character">
                                                <div className="title-character">
                                                    {character?.Name}
                                                </div>{' '}
                                                <img
                                                    style={{
                                                        width: '344px',
                                                        height: '450px',
                                                        objectFit: 'contain',
                                                    }}
                                                    src={character?.characterImage}
                                                    alt=""
                                                />
                                                <div className="container">
                                                    <div className="dropdown">
                                                        <div className="dropdown-menu">
                                                            <p className="dropdown-menu-center">
                                                                The details to character
                                                            </p>
                                                            <p className="dropdown-menu-bottom">
                                                                The orders you made for this
                                                                character.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <p className="character-text">
                                            Character Name: {character?.Name}
                                        </p>
                                        <p className="character-text">
                                            Character Class: {character?.Class}
                                        </p>
                                        <p className="character-text">
                                            Character Level: {character?.Level}
                                        </p>
                                        <p className="character-text">
                                            Character Strength:
                                            {character?.Strength}
                                        </p>
                                        <p className="character-text">
                                            Character Stamina:
                                            {character?.Stamina}
                                        </p>
                                        <p className="character-text">
                                            Character Dexterity:
                                            {character?.Dexterity}
                                        </p>
                                        <p className="character-text">
                                            Character Energy: {character?.Energy}
                                        </p>
                                        <p className="character-text">
                                            Character Stat Points:
                                            {character?.StatPoints}
                                        </p>
                                        <p className="character-text">
                                            Character Free Training Points:
                                            {character?.TrainingPoints}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Points:
                                            {character?.FiveElementPoints}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Fire:
                                            {character?.FiveElementFireEsp}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Wood:
                                            {character?.FiveElementWoodEsp}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Stool:
                                            {character?.FiveElementStoolEsp}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Water:
                                            {character?.FiveElementWaterEsp}
                                        </p>
                                        <p className="character-text">
                                            Character Five Element Soil:
                                            {character?.FiveElementSoilEsp}
                                        </p>
                                    </Popup>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <img
                            style={{
                                width: '36px',
                                height: '36px',
                                position: 'absolute',
                                top: '50%',
                                left: '0',
                            }}
                            ref={navigationPrevRef}
                            alt="leftArrow"
                            src={leftArrow}
                        />
                        <img
                            ref={navigationNextRef}
                            alt="rightArrow"
                            style={{
                                width: '36px',
                                height: '36px',
                                position: 'absolute',
                                top: '50%',
                                right: '0',
                            }}
                            src={rightArrow}
                        />
                    </div>
                </div>
                <div className="order-summary">
                    <p>Orders Summary</p>
                </div>
                {orders && orders.length > 0 ? (
                    <table className="table-items">
                        <tr>
                            <th colSpan="1">ORDER</th> <th colSpan="1">ITEMS QUANTITY</th>{' '}
                            <th colSpan="1">PRICE</th>
                        </tr>
                        {orders.map((order) => (
                            <tr>
                                <td>{order?.CharacterName ? 'Product' : 'Package'}</td>
                                <td>{order?.OrderDetails?.totalCartItems}</td>
                                <td>
                                    {order?.CharacterName ? '' : '$ '}
                                    {order?.OrderDetails?.totalCartPrice}
                                    {order?.CharacterName ? ' Coins' : ''}
                                </td>
                            </tr>
                        ))}
                    </table>
                ) : (
                    <p className="order_text"> No Orders found</p>
                )}
            </div>
        </div>
    );
}
