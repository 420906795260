import Cookie from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { AppContext } from '../../context/AppContext';
import UsernameWallet from '../common/UsernameWallet';
import ApiHelper from '../../data/ApiHelper';
import Snackbar from '../common/Snackbar';
import colors from '../../constants/colors';

export default function SendCoins(props) {
    const { state, dispatch } = useContext(AppContext);
    const { user } = state;
    const { match } = props;
    const { path } = match;
    const [coins, setCoins] = useState(0);
    const [username, setUsername] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [errorState, setErrorState] = useState({
        coins: null,
        user: null,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onLogout = () => {
        Cookie.remove('token');
        dispatch({ type: 'LOGOUT' });
        window.localStorage.setItem('logout', Date.now());
    };

    const validateCoinsHandler = () => {
        if (!coins) {
            setErrorState((prev) => ({
                ...prev,
                coins: 'Required',
            }));
            return false;
        }
        if (!username) {
            setErrorState((prev) => ({
                ...prev,
                user: 'Required',
            }));
            return false;
        }
        setTimeout(() => {
            setErrorState((prev) => ({
                ...prev,
                coins: null,
                user: null,
            }));
        });
        return true;
    };

    const onCoinChange = (amt) => {
        if (amt <= user.coin && amt >= 0) {
            setCoins(amt);
        }
    };

    const sendCoins = () => {
        const onSuccess = () => {
            const updatedUser = user;
            setShowAlert(true);
            setIsModalOpen(false);
            setUsername('');
            updatedUser.coin = user.coin - coins;
            dispatch({ type: 'UPDATE_USER', user: updatedUser });
            setCoins('');
            // window.location.reload();
        };
        const onError = () => {
            setIsModalOpen(false);
        };
        const data = {
            id: user.id,
            username,
            coins,
        };
        if (coins && user) {
            ApiHelper.updateCoins(data).then(onSuccess).catch(onError);
        }
    };

    return (
        <div className="page-content">
            <div className="page-top">SEND COINS</div>
            <div className="text-text">
                <div className="cart-user flex-s-c">
                    <UsernameWallet username={user.username} coin={user.coin} />
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <span className="cart-user-exit a" onClick={onLogout} />
                </div>
                <div className="menu-news-n">
                    <div className="menu-news flex-s-c">
                        <li className={path === '/myaccount' ? 'active-news' : ''}>
                            <Link to="/myaccount" className="a">
                                Dashboard
                            </Link>
                        </li>
                        <li className={path === '/myaccount/orders' ? 'active-news' : ''}>
                            <Link to="/myaccount/orders" className="a">
                                My Orders
                            </Link>
                        </li>
                        <li className={path === '/myaccount/edit' ? 'active-news' : ''}>
                            <Link to="/myaccount/edit" className="a">
                                Edit Account
                            </Link>
                        </li>
                        <li className={path === '/myaccount/send-coins' ? 'active-news' : ''}>
                            <Link to="/myaccount/send-coins" className="a">
                                Send Coins
                            </Link>
                        </li>
                    </div>
                </div>
                <div className="check_out_top flex-s-c">
                    <div className="check_out_top-right">
                        <span>Coins</span>
                        <input
                            className="check_out_input-r"
                            type="number"
                            name="text"
                            min={1}
                            onChange={(e) => onCoinChange(e.target.value)}
                            value={coins}
                            placeholder="Total Coins"
                        />
                        <p style={{ marginTop: '4px' }}>{errorState?.coins || ''}</p>
                    </div>
                    <div className="check_out_top-center">
                        send coins to:
                        <span className="check_out_top-center-span" />
                    </div>
                    <div className="check_out_top-right">
                        <span>other user</span>
                        <input
                            className="check_out_input-r"
                            type="text"
                            name="text"
                            onChange={(e) => setUsername(e.target.value)}
                            value={username}
                            placeholder="other user"
                        />
                        <p style={{ marginTop: '4px' }}>{errorState?.user || ''}</p>
                    </div>
                    <div className="sendCoinButtonContainer">
                        <button
                            type="button"
                            className="button"
                            onClick={() => validateCoinsHandler() && setIsModalOpen(true)}
                        >
                            send
                        </button>
                    </div>

                    <Popup
                        position="center center"
                        modal
                        open={isModalOpen}
                        closeOnDocumentClick={false}
                        contentStyle={{
                            width: '400px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div className="sendCoinConfirmModal">
                            <p>
                                Do you approve to send the amount of {coins} XL coins to the user
                                &nbsp;
                                {username} ?
                            </p>
                            <div style={{ display: 'flex', marginTop: '30px' }}>
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button" className="button" onClick={sendCoins}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Popup>
                </div>
            </div>
            <Snackbar
                message={{ body: 'Coins Sent Successfully', color: colors.success }}
                show={showAlert}
                setShow={setShowAlert}
            />
        </div>
    );
}
