// eslint-disable-next-line import/prefer-default-export
export const getDate = (publishedAt) => {
    const date = new Date(publishedAt);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

// // Converts the date in d to a date-object. The input can be:
// //   a date object: returned without modification
// //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
// //   a number     : Interpreted as number of milliseconds
// //                  since 1 Jan 1970 (a timestamp)
// //   a string     : Any format supported by the javascript engine, like
// //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
// //  an object     : Interpreted as an object with year, month and date
// //                  attributes.  **NOTE** month is 0-11.
// export const convertDate = (date) => {
//   return (
//       date.constructor === Date ? date :
//       date.constructor === Array ? new Date(date[0],date[1],date[2]) :
//       date.constructor === Number ? new Date(date) :
//       date.constructor === String ? new Date(date) :
//       typeof date === "object" ? new Date(date.year,date.month,date.date) :
//       NaN
//   );
// };

// // Compare two dates (could be of any type supported by the convert
// // function above) and returns:
// //  -1 : if a < b
// //   0 : if a = b
// //   1 : if a > b
// // NaN : if a or b is an illegal date
// // NOTE: The code inside isFinite does an assignment (=).
// export const compareDates = (a,b) => {
// return (
//     isFinite(a=this.convert(a).valueOf()) &&
//     isFinite(b=this.convert(b).valueOf()) ?
//     (a>b)-(a<b) :
//     NaN
// );
// };
