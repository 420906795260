import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ApiHelper from '../../data/ApiHelper';
import TopGuild from './TopGuild/TopGuild';
import './TopGuilds.css';

export default function TopGuilds() {
    const [topGuilds, setTopGuilds] = useState([]);
    // const [total, setTotal] = useState(0);
    useEffect(() => {
        const fetchTopGuilds = () => {
            const onSuccess = (response) => {
                const data = get(response, 'data.guild.0', []);

                // const totalCount = get(response, 'data.total.0.0', []);
                setTopGuilds(data);
                // setTotal(totalCount['']);
            };

            const onError = () => {
                setTopGuilds([]);
            };

            ApiHelper.games.getTopTenGuilds().then(onSuccess).catch(onError);
        };

        fetchTopGuilds();
    }, []);
    const TopTenGuilds = topGuilds.map((item, index) => (
        <TopGuild
            key={uuidv4()}
            number={index + 1}
            name={item.Name}
            guild={item.Fame}
            total={item.MemberCount}
        />
    ));

    const TopSeven = TopTenGuilds.slice(0, 10);
    return (
        <div className="guilds-guilds stat-block block-4">
            <h3>TOP GUILDS</h3>
            <ul className="top-block">
                <li className="top-title" key={uuidv4()}>
                    <span className="top-number">#</span>
                    <span className="top-name">Guild Name</span>
                    <span className="top-lvl">Rank</span>
                    <span className="top-Res">TotalPlayers</span>
                </li>
                {TopSeven}
            </ul>
        </div>
    );
}
